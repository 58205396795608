import React from 'react'
import { graphql, navigate, StaticQuery } from 'gatsby'
import './Header.scss'
import Container from '../Container'
import Logo from '../../assets/icons/callicoder.svg'
import SearchIcon from '../../assets/icons/search.svg'
import CloseIcon from '../../assets/icons/close.svg'
import MobileNav from '../MobileNav'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.searchBoxRef = React.createRef();
    this.state = {
      searchOpen: false,
      searchTerm: '',
      navOpen: false 
    }
  }

  openSearch = event => {
    this.setState({
      searchOpen: true
    }, () => {
      this.searchBoxRef.current.focus()
    })
  }

  closeSearch = event => {
    this.setState({
      searchOpen: false,
      searchTerm: ''
    })
  }

  handleSearchInput = event => {
    this.setState({
      searchTerm: event.target.value
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    navigate(`/search?q=${this.state.searchTerm}`)
  }

  openNav = event => {
    this.setState({
      navOpen: true
    })
  }

  closeNav = event => {
    this.setState({
      navOpen: false
    })
  }

  render () {

    return (
      <StaticQuery
        query={graphql`
          query NavQuery {
            site {
              siteMetadata {
                nav {
                  title
                  slug
                }
              }
            }
          }`
        }
        render={data => (
          <header
            className={`site-header shadowed ${this.state.searchOpen ? 'search-open': ''}`}
          >
            <Container>
              <button className={`menu ${this.state.navOpen ? 'open': ''}`} onClick={this.openNav}>
                <div className="line-wrap">
                  <div className="line top"></div>
                  <div className="line center"></div>
                  <div className="line bottom"></div>
                </div>
              </button>

              <div className="site-branding">
                <a href={'/'} className="site-logo">
                  <Logo />
                  <span>{this.props.siteMeta.name}</span>
                </a>
              </div>

              <div className="mobile-search">
                  <button type="button" className="search-opener" onClick={this.openSearch}>
                    <SearchIcon />
                  </button>      
                  <button type="button" className="search-close" onClick={this.closeSearch}>
                    <CloseIcon />
                  </button>      
              </div>

              <div className="header-search">
                <form onSubmit={this.handleSubmit} id="cse-search-box-form-id">
                  <div className={`search-form-container ${this.state.searchTerm ? 'has-input': null}`}>
                    <input type="text" 
                          className="search-box" 
                          ref={this.searchBoxRef} 
                          id="cse-search-input-box-id" 
                          autoComplete="off" 
                          placeholder="Search TechSangam"
                          value={this.state.searchTerm} 
                          onChange={this.handleSearchInput} />
                    <button type="button" className="search-submit">
                      <SearchIcon />
                    </button>
                  </div>
                </form>
              </div>

              <nav className="site-nav">
                <ul className="nav-list">
                  {data.site.siteMetadata.nav.map((navItem, navIndex) =>
                    <li key={navIndex} className="nav-item">
                      <a href={navItem.slug}>{navItem.title}</a>
                    </li>
                  )}
                </ul>
              </nav>

              <nav className={`navigation-drawer ${this.state.navOpen ? 'open opened': ''}`} id="nav-drawer">
                <MobileNav location={this.props.location} navList={this.props.navList ? this.props.navList : data.site.siteMetadata.nav} navGroup={this.props.navGroup} />             
              </nav>

              <div className={`nav-shadow ${this.state.navOpen ? 'open': ''}`} onClick={this.closeNav}></div>
            </Container>
          </header>

        )}
      />
    )
  }
}

export default Header
