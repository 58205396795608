import React, { useState, useEffect } from 'react'
import './MobileNav.scss'

const MobileNav = ({location, navTitle, navList, navGroup}) => {
    
    const [currentNav, setCurrentNav] = useState(-1)

    useEffect(() => {
        if(!navGroup) {
            return
        }

        for(let i = 0; i < navList.length; i++) {
            const navItem = navList[i]
            if(navItem.group === navGroup) {
                setCurrentNav(i)
                break
            }
        }
    }, []);

    const toggleNav = (event, index) => {
        event.preventDefault()
        setCurrentNav(index)
    }

    return(
        <div className="mobile-nav">
            {navTitle ? 
            <h3 className="nav-title">
                {navTitle}
            </h3>: null
            }
            <ul className="top-nav">
                {
                    navList.map((navItem, navIndex) => {
                        return (
                                <li key={`top-nav-${navIndex}`} className={`nav-item ${navItem.subNav ? 'has-sub-nav': ''} ${navGroup && navIndex === currentNav ? 'open': ''}`}>
                                    <a href={navItem.slug || '#'} className={`nav-toggle ${location.pathname === navItem.slug ? 'active': ''}`} onClick={navGroup ? (event) => toggleNav(event, navIndex): null}>{navItem.title}</a>
                                    {
                                        navItem.subNav ? 
                                        <ul className="sub-nav">
                                            {
                                                navItem.subNav.map((subNavItem, subNavIndex) => 
                                                    <li key={`sub-nav-${subNavIndex}`}>
                                                        <a href={subNavItem.slug} className={`${location.pathname === subNavItem.slug ? 'active': ''}`}>{subNavItem.title}</a>
                                                    </li>                                
                                                )
                                            }
                                        </ul>: null
                                    }
                                </li>
                        )
                                
                    })
                }  
            </ul>
        </div>
    )
}

export default MobileNav