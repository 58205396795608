import React from 'react'

const AWS = ({title, ASIN}) => {
    let tag = "callicoder-20"
    return (
        <div className="align-center filter">
            <a href={`https://www.amazon.com/gp/product/${ASIN}?tag=${tag}`} rel="nofollow" target="_blank">
                <img 
                    border="0" 
                    alt={title}
                    height="300"
                    src={`https://images-na.ssl-images-amazon.com/images/P/${ASIN}.jpg&tag=${tag}`} />
            </a>
        </div>         
    )
}

export default AWS