import React from 'react'
import 'typeface-open-sans'
import './Layout.scss'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { MDXProvider } from "@mdx-js/react"
import Adsense from '../components/Adsense'
import Youtube from '../components/Youtube'
import AWS from '../components/AWS'

class Layout extends React.Component {
  render () {
    const shortcodes = { Adsense, Youtube, AWS }
    const { location, siteMeta, navList, navGroup} = this.props
    
    return (
      <MDXProvider components={shortcodes}>
        <div className="layout">
          <Header location={location} siteMeta={siteMeta} navList={navList} navGroup={navGroup} />
          {this.props.children}
          <Footer location={location} siteMeta={siteMeta} />
        </div>
      </MDXProvider>
    )
  }
}

export default Layout
